// 2024 Schedule: https://docs.google.com/spreadsheets/d/1XTe3srNSBz5XaffIZ1JU2WRgl94erOEF-Fftir5LaXI/edit?pli=1#gid=847215038
// Look at Column "AC"
export default [
  {
    name: "Week 1",
    startDate: "2024-02-21",
    endDate: "2024-02-25",
  },
  {
    name: "Week 2",
    startDate: "2024-03-02",
    endDate: "2024-03-03",
  },
  {
    name: "Week 3",
    startDate: "2024-03-09",
    endDate: "2024-03-10",
  },
  {
    name: "Week 4",
    startDate: "2024-03-16",
    endDate: "2024-03-17",
  },
  {
    name: "Week 5",
    startDate: "2024-03-23",
    endDate: "2024-03-24",
  },
  {
    name: "Week 6",
    startDate: "2024-03-30",
    endDate: "2024-03-31",
  },
  {
    name: "Week 7",
    startDate: "2024-04-06",
    endDate: "2024-04-07",
  },
  {
    name: "Week 8",
    startDate: "2024-04-13",
    endDate: "2024-04-14",
  },
  {
    name: "Week 9",
    startDate: "2024-04-20",
    endDate: "2024-04-21",
  },
  {
    name: "Week 10",
    startDate: "2024-04-27",
    endDate: "2024-04-28",
  },
  {
    name: "Week 11",
    startDate: "2024-05-04",
    endDate: "2024-05-05",
  },
  {
    name: "Week 12",
    startDate: "2024-05-11",
    endDate: "2024-05-12",
  },
  {
    name: "Week 13",
    startDate: "2024-05-15",
    endDate: "2024-05-16",
  },
  {
    name: "Week 14",
    startDate: "2024-05-18",
    endDate: "2024-05-19",
  },
  {
    name: "Week 15",
    startDate: "2024-05-25",
    endDate: "2024-05-26",
  },
  {
    name: "Week 16",
    startDate: "2024-05-29",
    endDate: "2024-05-30",
  },
  {
    name: "Week 17",
    startDate: "2024-05-31",
    endDate: "2024-06-02",
  },
  {
    name: "Week 18",
    startDate: "2024-06-08",
    endDate: "2024-06-09",
  },
  {
    name: "Week 19",
    startDate: "2024-06-14",
    endDate: "2024-06-15",
  },
  {
    name: "Week 20",
    startDate: "2024-06-19",
    endDate: "2024-06-20",
  },
  {
    name: "Week 21",
    startDate: "2024-07-22",
    endDate: "2024-07-23",
  },
  {
    name: "Week 22",
    startDate: "2024-07-28",
    endDate: "2024-07-29",
  },
  {
    name: "Week 23",
    startDate: "2024-07-03",
    endDate: "2024-07-04",
  },
  {
    name: "Week 24",
    startDate: "2024-07-06",
    endDate: "2024-07-08",
  },
  {
    name: "Week 25",
    startDate: "2024-08-13",
    endDate: "2024-08-14",
  },
  {
    name: "Week 26",
    startDate: "2024-07-17",
    endDate: "2024-07-18",
  },
  {
    name: "Week 27",
    startDate: "2024-07-20",
    endDate: "2024-07-21",
  },
  {
    name: "Week 28",
    startDate: "2024-08-24",
    endDate: "2024-08-25",
  },
  {
    name: "Week 29",
    startDate: "2024-08-31",
    endDate: "2025-09-01",
  },
  {
    name: "Week 30",
    startDate: "2025-09-01",
    endDate: "2025-09-01",
  },
  {
    name: "Week 31",
    startDate: "2024-09-07",
    endDate: "2024-09-08",
  },
  {
    name: "Week 32",
    startDate: "2024-09-14",
    endDate: "2024-09-15",
  },
  {
    name: "Week 33",
    startDate: "2024-09-18",
    endDate: "2024-09-19",
  },
  {
    name: "Week 34",
    startDate: "2024-09-21",
    endDate: "2024-09-22",
  },
  {
    name: "Week 35",
    startDate: "2024-09-28",
    endDate: "2024-09-29",
  },
  {
    name: "Week 36",
    startDate: "2024-10-02",
    endDate: "2024-10-03",
  },
  {
    name: "Week 37",
    startDate: "2024-10-05",
    endDate: "2024-10-06",
  },
  {
    name: "Week 38",
    startDate: "2024-10-18",
    endDate: "2024-10-19",
  },
  {
    name: "Wild Card",
    startDate: "2024-10-25",
    endDate: "2024-10-27",
  },
  {
    name: "Round One",
    startDate: "2024-11-02",
    endDate: "2024-11-17",
  },
  {
    name: "Conf Semifinals",
    startDate: "2024-11-23",
    endDate: "2024-11-24",
  },
  {
    name: "Conf Finals",
    startDate: "2024-11-30",
    endDate: "2024-12-01",
  },
  {
    name: "MLS Cup",
    startDate: "2024-12-07",
    endDate: "2024-12-08",
  },
];
